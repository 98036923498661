import '../Link/Link.css';

import React from 'react';

import AppContext from '../../context/appContext';

const AccessDenied = () => {
    const context = React.useContext(AppContext);
    React.useEffect(() => {
        document.title = 'Coop | Behörighet saknas';
    }, []);

    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Hoppsan</h1>
            <p>Det verkar som du försöker komma åt något du ej har rättighet till.</p>
            <br />
            <a className="Link" href="/">
                Tillbaka
            </a>
        </>
    );
};

export default AccessDenied;
