import { Button } from '@coop/components';
import React from 'react';

import { getCoopseLoginUrl } from '../../common/utils';

const Success = () => {
    return (
        <div>
            <p className="u-textCenter">Ditt konto har nu tagits bort från coop.se.</p>
            <p className="u-textCenter u-marginT">
                <Button asChild theme="primary" fullWidth size={48}>
                    <a href={getCoopseLoginUrl()}>Gå till Coop.se</a>
                </Button>
            </p>
        </div>
    );
};

export default Success;
