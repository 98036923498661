import React from 'react';

import type { AuthenticationStateResult } from '../common/api/api.types';

export interface IAppContext {
    backUrl: string;
    setBackUrl: React.Dispatch<React.SetStateAction<string>>;
    showNavigation: boolean;
    setShowNavigation: React.Dispatch<React.SetStateAction<boolean>>;
    showBackIcon: boolean;
    setShowBackIcon: React.Dispatch<React.SetStateAction<boolean>>;
    bankIdOnly: boolean;
    authState: AuthenticationStateResult;
    refetchAuthState: () => Promise<void>;
}

const AppContext = React.createContext<IAppContext | null>(null);
export default AppContext;
