import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    max-width: 800px;
    padding: 20px;
    background: #ffffffee;
    gap: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    max-height: 100%;
`;

export default Wrapper;
