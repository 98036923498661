import { Button } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException } from '../../../common/api/api.types';
import { regex } from '../../../common/utils';
import InformationBox from '../../../components/InformationBox/InformationBox';
import TextInputRHF from '../../../components/TextInputRHF/TextInputRHF';
import { isExternalUserAuthenticatedWithPassword } from '../../../core/authState.utils';
import useLoginContext from '../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';

interface ChangePasswordFields {
    NewEmail: string;
    Password: string;
}

const ChangeEmailWithEmail = () => {
    const context = useLoginContext();
    const navigate = useLoginNavigate();
    const { formState, getValues, control } = useForm<ChangePasswordFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const changeEmailClick = async () => {
        if (!context.authState.currentUser) {
            return;
        }
        isEmailInUseMutation.reset();
        if (formState.isValid) {
            if (!isExternalUserAuthenticatedWithPassword(context.authState)) {
                signinMutation.mutate({
                    email: context.authState.currentUser?.email,
                    password: getValues('Password'),
                    accountType: context.authState.currentUser?.isCompany ? 'Company' : 'Private',
                });
            } else {
                isEmailInUseMutation.mutate(getValues('NewEmail'));
            }
        }
    };
    const isEmailInUseMutation = useMutation<boolean, ApiException, string>({
        mutationFn: (email) => accountApi.isEmailInUse(email),
        onError: () => {
            context.refetchAuthState();
        },
        onSuccess: (isInUse) => {
            if (!isInUse) {
                sendEmailVerificationCode.mutate({
                    email: getValues('NewEmail'),
                });
            }
        },
    });
    const signinMutation = useMutation<
        void,
        ApiException,
        { email: string; password: string; accountType: 'Private' | 'Company' }
    >({
        mutationFn: async (params) => {
            await accountApi.signinWithEmailPasswordToExternalSchema({
                email: params.email,
                password: params.password,
                accountType: params.accountType,
            });
        },
        onError: () => {
            context.refetchAuthState();
        },
        onSuccess: () => {
            context.refetchAuthState();
            isEmailInUseMutation.mutate(getValues('NewEmail'));
        },
    });
    const sendEmailVerificationCode = useMutation<void, ApiException, { email: string }>({
        mutationFn: async (params) => {
            await accountApi.sendChangeEmailVerificationCode(params.email);
        },
        onError: () => {
            context.refetchAuthState();
        },
        onSuccess: () => {
            navigate(SpaRoutes.ChangeEmail.Verify(getValues('NewEmail')));
        },
    });

    if (!context.authState.currentUser) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">
                    Ändra
                    <br />
                    e-postadress
                </h1>
                <p>För att ändra e-post behöver du vara inloggad.</p>
            </>
        );
    }
    const isDisabled =
        !formState.isValid || sendEmailVerificationCode.isPending || signinMutation.isPending;
    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                Ändra
                <br />
                e-postadress
            </h1>

            <p className="u-textWeightBold">
                {context.authState.currentUser?.medmeraId
                    ? 'Ny e-postadress för inlogg och medlemskap'
                    : 'Ny e-postadress för inlogg'}
            </p>

            <Controller
                render={({ field, fieldState }) => (
                    <TextInputRHF
                        containerClass="u-marginTxsm"
                        label="E-postadress"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                name="NewEmail"
                rules={{
                    required: true,
                    pattern: {
                        value: regex.emailSpaceAtEnd,
                        message: 'Angiven e-postadress har fel format.',
                    },
                }}
                defaultValue=""
                control={control}
            />
            <p className="u-textWeightBold u-marginTlg">Bekräfta med ditt lösenord</p>

            <Controller
                render={({ field, fieldState }) => (
                    <TextInputRHF
                        containerClass="u-marginTxsm"
                        type="password"
                        label="Lösenord"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                name="Password"
                rules={{
                    required: 'Ange lösenord.',
                }}
                defaultValue=""
                control={control}
            />

            <footer className="u-marginTauto">
                {!!context.authState.currentUser?.medmeraId && (
                    <InformationBox
                        text={<>Du ändrar nu e-postadress för både inloggning och medlemsskap</>}
                    />
                )}
                {sendEmailVerificationCode.isError && (
                    <p className="Notice Notice--red">
                        {sendEmailVerificationCode.error.friendlyMessage}
                    </p>
                )}
                {isEmailInUseMutation.isError && (
                    <p className="Notice Notice--red">
                        {isEmailInUseMutation.error.friendlyMessage}
                    </p>
                )}
                {isEmailInUseMutation.data && (
                    <p className="Notice Notice--red">E-postadressen är redan upptagen</p>
                )}
                {signinMutation.isError && (
                    <p className="Notice Notice--red">{signinMutation.error.friendlyMessage}</p>
                )}
                <Button
                    theme="light"
                    size={48}
                    fullWidth
                    className="u-marginTmd"
                    disabled={isDisabled}
                    onClick={changeEmailClick}
                >
                    Gå vidare
                </Button>
            </footer>
        </>
    );
};

export default ChangeEmailWithEmail;
