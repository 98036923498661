import type { FieldValues, Path, UseFormSetError } from 'react-hook-form';

import type { ApiException } from '../common/api/api.types';

export const handleFormApiErrors = <T extends FieldValues>(
    error: ApiException,
    setError: UseFormSetError<T>,
) => {
    if (error && error.field && error.friendlyMessage) {
        const field = error.field as Path<T>;
        setError(field, { message: error.friendlyMessage }, { shouldFocus: true });
    }
};
