/* eslint-disable no-param-reassign */
import type { Page, PageResult } from './Pagination.types';

export const calculatePaging = (
    currentPage: number,
    totalResults: number,
    resultsPerPage: number,
    range: number,
): PageResult => {
    const totalPages = Math.ceil(totalResults / resultsPerPage);
    if (range === 0) range = 1;

    // Since both sides of the pagenumber you are on needs to be same, the range number must be odd
    if (range % 2 === 0) {
        range += 1;
    }

    if (range > totalPages) range = totalPages;
    if (currentPage <= 0) currentPage = 1;

    const rangePerSide = Math.floor(range / 2);
    let start = currentPage - rangePerSide;
    start = Math.max(start, 1);
    start = Math.min(start, 1 + totalPages - range);

    const leftComplement = start > 1;
    const rightComplement = start + range - 1 < totalPages;

    const pages: Page[] = [];

    for (let i = 0; i < range; i += 1) {
        pages.push({
            pageNumber: start + i,
            isCurrentPage: start + i === currentPage,
            resultsPerPage,
            startIndex: (start + i - 1) * resultsPerPage,
        });
    }

    if (leftComplement) {
        pages.splice(0, rangePerSide - 1, {
            pageNumber: 1,
            isCurrentPage: false,
            complementaryPageType: 'left',
            resultsPerPage,
            startIndex: 0,
        });
    }

    if (rightComplement) {
        pages.splice(pages.length - rangePerSide + 1, rangePerSide, {
            pageNumber: totalPages,
            isCurrentPage: false,
            complementaryPageType: 'right',
            resultsPerPage,
            startIndex: (totalPages - 1) * resultsPerPage,
        });
    }

    return {
        pages,
        totalPages,
    };
};
