import './TabNav.css';

import React from 'react';

interface TabNavProps {
    names: string[];
    selectedIndex: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
    children: JSX.Element[];
    disabled?: boolean;
}

const TabNav = (props: TabNavProps) => {
    return (
        <>
            <nav className="TabNav u-marginTlg">
                {React.Children.map(props.children, (child, index) => {
                    return (
                        <button
                            disabled={props.disabled}
                            className={`TabNav-button ${
                                props.selectedIndex === index ? 'is-selected' : ''
                            }`}
                            type="button"
                            onClick={() => props.setSelectedIndex(index)}
                        >
                            {props.names[index]}
                        </button>
                    );
                })}
            </nav>
            {React.Children.map(props.children, (child, index) => {
                return index === props.selectedIndex ? child : '';
            })}
        </>
    );
};

export default TabNav;
