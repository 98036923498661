import { Button } from '@coop/components';
import React from 'react';

import { getCoopseLoginUrl } from '../common/utils';
import AppContext from '../context/appContext';
import { useAuthState } from '../hooks/useAuthState';

const LoggedIn = () => {
    const context = React.useContext(AppContext);
    const { data: authState } = useAuthState();
    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Inloggad som</h1>
            <p className="u-textCenter u-marginTmd">
                <strong>{authState?.currentUser?.email}</strong>
            </p>
            <footer className="u-marginTauto">
                <p className="u-textCenter u-marginT">
                    <Button asChild theme="primary" fullWidth size={48}>
                        <a href={getCoopseLoginUrl()}>Gå till Coop.se</a>
                    </Button>
                </p>
                <p className="u-textCenter u-marginT">
                    <a href="/logga-ut?returnUrl=/">Logga ut</a>
                </p>
            </footer>
        </>
    );
};

export default LoggedIn;
