import type { InternalAxiosRequestConfig } from 'axios';

import { getCookie } from '../utils';
import type { ApiException } from './api.types';

export const isApiException = (object: unknown): object is ApiException => {
    return !!object && (object as ApiException).friendlyMessage !== undefined;
};

export const addXsrfTokenToHeader = (config: InternalAxiosRequestConfig) => {
    // Do something before request is sent
    config.headers.set('RequestVerificationToken', getCookie('XSRF-TOKEN') || '');
    return config;
};
