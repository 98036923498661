import { useMutation } from '@tanstack/react-query';
import React from 'react';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException } from '../../../common/api/api.types';
import { getCoopseLoginUrl } from '../../../common/utils';
import BankId from '../../../components/BankId/BankId';
import { useBankid } from '../../../components/BankId/BankId.hooks';
import useLoginContext from '../../../hooks/useLoginContext';
import { useWindowSize } from '../../../hooks/useWindowSize';

const ConfirmBankId = () => {
    const context = useLoginContext();

    const onBankidSuccess = () => {
        signInMutation.mutate(context.authState.currentUser?.rememberMe === true);
    };

    const returnUrl = context.authState.loginRequest?.returnUrl || getCoopseLoginUrl();

    const signInMutation = useMutation<void, ApiException, boolean>({
        mutationFn: (rememberMe: boolean) =>
            accountApi.signinApplicationWithExternalSchema(
                rememberMe,
                context.authState.loginRequest?.loginHint,
            ),
        onSuccess: () => {
            window.location.href = decodeURIComponent(returnUrl);
        },
    });

    const {
        start,
        cancel,
        isStarted,
        isCollecting,
        progress,
        message,
        errorMessage,
        autoStartToken,
        qrCode,
    } = useBankid({
        isConfirm: false,
        onSuccess: onBankidSuccess,
    });
    const windowSize = useWindowSize();

    const fullName = context.authState.currentUser?.firstname
        ? `${context.authState.currentUser.firstname} ${context.authState.currentUser.lastname}`
        : undefined;

    const isLoggedInWithDifferentUserAsHinted =
        context.authState.currentUser?.id &&
        context.authState.loginRequest?.loginHint &&
        context.authState.currentUser?.id !== context.authState.loginRequest?.loginHint;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Bekräfta med BankID</h1>
            <p className="u-textCenter">
                Vill du identifiera dig eller skriva under med{' '}
                {windowSize.width > 1024
                    ? ' BankID på den här datorn eller med ett Mobilt BankID?'
                    : ' ett BankID på den här enheten eller med ett BankID på en annan enhet?'}
            </p>
            {!isLoggedInWithDifferentUserAsHinted && fullName && (
                <p className="u-textCenter">
                    BankID inloggning för <strong>{fullName}</strong>
                </p>
            )}

            {signInMutation.isError && (
                <p className="Notice Notice--red">{signInMutation.error.friendlyMessage}</p>
            )}
            <BankId
                disabled={signInMutation.isPending || signInMutation.isSuccess}
                start={start}
                cancel={cancel}
                isStarted={isStarted}
                isCollecting={isCollecting}
                progress={progress}
                message={message}
                errorMessage={errorMessage}
                autoStartToken={autoStartToken}
                qrCode={qrCode}
            />
        </>
    );
};

export default ConfirmBankId;
