import { Button } from '@coop/components';
import React from 'react';

import { getCoopseUrl } from '../../common/utils';
import AppContext from '../../context/appContext';

const AccountRemoved = () => {
    const context = React.useContext(AppContext);
    React.useEffect(() => {
        document.title = 'Coop | Kontot borttaget';
    }, []);

    React.useEffect(() => {
        context?.setShowNavigation(false);
    }, [context]);

    const coopSeUrl = getCoopseUrl('accountRemoved');

    // redirect to www.coop.se after 5 seconds
    window.setTimeout(() => {
        window.location.href = coopSeUrl;
    }, 5000);

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Kontot borttaget</h1>
            <p className=" u-textCenter">Din användare på coop.se har nu raderats.</p>
            <br />
            <Button asChild size={48} fullWidth>
                <a className="Button Button--green" href={coopSeUrl}>
                    Till www.coop.se
                </a>
            </Button>
        </>
    );
};

export default AccountRemoved;
