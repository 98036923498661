import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Authenticate from './Authenticate';
import EmailNew from './ny-epost/EmailNew';
import VerifyNewEmail from './ny-epost/verifiera-epost/VerifyNewEmail';
import RegisterEmail from './registrera-epost/RegisterEmail';
import EmailAlreadyConnected from './upptagen-epost/EmailAlreadyConnected';
import VerifyEmailExistingAccount from './upptagen-epost/verifiera-epost/VerifyEmailExistingAccount';

const CreateAccountBankid = () => (
    <Routes>
        <Route path="/upptagen-epost/:email/verifiera" element={<VerifyEmailExistingAccount />} />
        <Route path="/upptagen-epost/:email" element={<EmailAlreadyConnected />} />
        <Route path="/ny-epost/:email" element={<EmailNew />} />
        <Route path="/ny-epost/:email/verifiera" element={<VerifyNewEmail />} />
        <Route path="/registrera-epost" element={<RegisterEmail />} />
        <Route path="/*" element={<Authenticate />} />
    </Routes>
);

export default CreateAccountBankid;
