/* eslint-disable react/jsx-props-no-spreading */
import { TextInput } from '@coop/components';
import * as React from 'react';
import { v4 } from 'uuid';
/**
 * Stateful Implementation of SimpleTextInput.
 * can be used with react-hook-form and have same validation behaviour in forms as hybris
 */

/**
 * Why is this component here? Currently React Form hook does not have all states needed to show green check marks properly. This components is using internal states
 * to prevent showing green checkmarks or red marks for errors too early.
 * This would be espeically visible if the validation rule is something other than required (for example regex pattern)
 */

interface TextInputRHFProps
    extends Omit<React.ComponentProps<typeof TextInput>, 'validationState' | 'value'> {
    isInvalid?: boolean;

    value?: string;
}

const TextInputRHF = React.forwardRef<HTMLInputElement, TextInputRHFProps>(
    (props, forwardedRef) => {
        const [uniqueIdForAria] = React.useState(() => v4());
        const [shouldShowInvalid, setShouldShowInvalid] = React.useState(true);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setShouldShowInvalid(false);
            if (props.onChange) {
                props.onChange(event);
            }
        };

        const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            setShouldShowInvalid(true);
            if (props.onBlur) {
                props.onBlur(event);
            }
        };

        const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
            if (props.onFocus) {
                props.onFocus(event);
            }
        };

        const isValid = () => {
            if (props.disabledReason) return undefined;

            if (props.value && props.value.length > 0 && !props.isInvalid) {
                return 'valid';
            }

            if (props.isInvalid && shouldShowInvalid) {
                return 'invalid';
            }

            return undefined;
        };

        return (
            <TextInput
                {...props}
                ref={forwardedRef}
                id={uniqueIdForAria}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                validationState={isValid()}
            />
        );
    },
);

export default TextInputRHF;
