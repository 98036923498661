/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException } from '../../../common/api/api.types';
import { getCoopseUrl, regex } from '../../../common/utils';
import BankId from '../../../components/BankId/BankId';
import { useBankid } from '../../../components/BankId/BankId.hooks';
import TextInputRHF from '../../../components/TextInputRHF/TextInputRHF';
import {
    isExternalUserAuthenticatedWithBankid,
    isUserAuthenticatedWithBankid,
} from '../../../core/authState.utils';
import useLoginContext from '../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../hooks/useLoginNavigate';

const ChangeEmailWithBankid = () => {
    const context = useLoginContext();
    const navigate = useLoginNavigate();
    const { control, formState, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const changeEmailClick = () => {
        if (formState.isValid) {
            sendEmailVerificationCodeMutation.reset();
            isEmailInUseMutation.mutate(getValues('NewEmail'));
        }
    };

    const isEmailInUseMutation = useMutation<boolean, ApiException, string>({
        mutationFn: (email) => accountApi.isEmailInUse(email),
        onError: () => {
            context.refetchAuthState();
        },
        onSuccess: (isInUse) => {
            if (!isInUse) {
                sendEmailVerificationCodeMutation.mutate(getValues('NewEmail'));
            }
        },
    });

    const sendEmailVerificationCodeMutation = useMutation<void, ApiException, string>({
        mutationFn: (email) => accountApi.sendChangeEmailVerificationCode(email),
        onError: () => {
            context.refetchAuthState();
        },
        onSuccess: () => {
            navigate(SpaRoutes.ChangeEmail.Verify(getValues('NewEmail')));
        },
    });

    const {
        start,
        cancel,
        isStarted,
        isCollecting,
        progress,
        message,
        errorMessage,
        autoStartToken,
        qrCode,
    } = useBankid({
        isConfirm: false,
        onSuccess: changeEmailClick,
    });

    const fullName = context.authState.currentUser?.firstname
        ? `${context.authState.currentUser.firstname} ${context.authState.currentUser.lastname}`
        : undefined;

    const isLoggedInWithDifferentUserAsHinted =
        context.authState.currentUser?.id &&
        context.authState.loginRequest?.loginHint &&
        context.authState.currentUser?.id !== context.authState.loginRequest?.loginHint;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">
                Ändra
                <br />
                e-postadress
            </h1>

            {!isLoggedInWithDifferentUserAsHinted && fullName && (
                <p className="u-textCenter">
                    BankID inloggning för <strong>{fullName}</strong>
                </p>
            )}
            {isEmailInUseMutation.data && (
                <p className="Notice Notice--red">E-postadressen är redan upptagen.</p>
            )}
            {sendEmailVerificationCodeMutation.isError && (
                <p className="Notice Notice--red">
                    {sendEmailVerificationCodeMutation.error.friendlyMessage}
                </p>
            )}
            <footer className="u-marginTauto">
                {!isUserAuthenticatedWithBankid(context.authState) && (
                    <>
                        <p className="Notice Notice--red">Oj, du har visst hamnat fel.</p>
                        <a
                            className="Button"
                            href={context.authState.redirectUrl || getCoopseUrl()}
                        >
                            Gå Tillbaka
                        </a>
                    </>
                )}
                {isUserAuthenticatedWithBankid(context.authState) && (
                    <>
                        {isExternalUserAuthenticatedWithBankid(context.authState) && (
                            <Controller
                                render={({ field, fieldState }) => (
                                    <TextInputRHF
                                        containerClass="u-marginTxsm"
                                        label="E-postadress"
                                        value={field.value}
                                        name={field.name}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        ref={field.ref}
                                        validationText={fieldState.error?.message}
                                        isInvalid={!!fieldState.error}
                                    />
                                )}
                                name="NewEmail"
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: regex.emailSpaceAtEnd,
                                        message: 'Angiven e-postadress har fel format.',
                                    },
                                }}
                                defaultValue=""
                                control={control}
                            />
                        )}
                        {!isExternalUserAuthenticatedWithBankid(context.authState) ? (
                            <>
                                <p className="u-marginBmd">
                                    Verifiera din inloggning med BankId för att ändra e-post.
                                </p>
                                <BankId
                                    disabled={
                                        sendEmailVerificationCodeMutation.isPending ||
                                        sendEmailVerificationCodeMutation.isSuccess ||
                                        !formState.isValid
                                    }
                                    start={start}
                                    cancel={cancel}
                                    isStarted={isStarted}
                                    isCollecting={isCollecting}
                                    progress={progress}
                                    message={message}
                                    errorMessage={errorMessage}
                                    autoStartToken={autoStartToken}
                                    qrCode={qrCode}
                                />
                            </>
                        ) : (
                            <Button
                                theme="light"
                                size={48}
                                fullWidth
                                className="u-marginTmd"
                                disabled={
                                    sendEmailVerificationCodeMutation.isPending ||
                                    sendEmailVerificationCodeMutation.isSuccess ||
                                    !formState.isValid
                                }
                                onClick={changeEmailClick}
                            >
                                Gå vidare
                            </Button>
                        )}
                    </>
                )}
            </footer>
        </>
    );
};

export default ChangeEmailWithBankid;
