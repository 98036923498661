/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../common/api/api.accounts';
import type { ApiException } from '../../../common/api/api.types';
import { getCoopseUrl, regex } from '../../../common/utils';
import BankId from '../../../components/BankId/BankId';
import { useBankid } from '../../../components/BankId/BankId.hooks';
import { PasswordStrengthIndicator } from '../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import TextInputRHF from '../../../components/TextInputRHF/TextInputRHF';
import {
    isExternalUserAuthenticatedWithBankid,
    isUserAuthenticatedWithBankid,
} from '../../../core/authState.utils';
import useLoginContext from '../../../hooks/useLoginContext';

const ChangePasswordBankid = () => {
    const context = useLoginContext();

    const { control, formState, getValues, watch } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const changePassword = () => {
        if (formState.isValid) {
            changePasswordMutation.mutate(getValues('Password'));
        }
    };

    const changePasswordMutation = useMutation<void, ApiException, string>({
        mutationFn: (password: string) => accountApi.changePasswordWithBankid(password),
        onSuccess: async () => {
            await accountApi.signOutExternalScheme();
        },
        onError: () => {
            accountApi.signOutExternalScheme();
            context.refetchAuthState();
        },
    });

    const {
        start,
        cancel,
        isStarted,
        isCollecting,
        progress,
        message,
        errorMessage,
        autoStartToken,
        qrCode,
    } = useBankid({
        isConfirm: false,
        onSuccess: changePassword,
    });

    const fullName = context.authState.currentUser?.firstname
        ? `${context.authState.currentUser.firstname} ${context.authState.currentUser.lastname}`
        : undefined;

    const isLoggedInWithDifferentUserAsHinted =
        context.authState.currentUser?.id &&
        context.authState.loginRequest?.loginHint &&
        context.authState.currentUser?.id !== context.authState.loginRequest?.loginHint;

    if (changePasswordMutation.isSuccess) {
        return (
            <>
                <h1 className="Heading Heading--large u-textCenter">Skapa lösenord</h1>
                <p className="u-textCenter u-marginTlg">
                    Ditt lösenord för{' '}
                    <strong className="u-textNoWrap">{context.authState.currentUser?.email}</strong>{' '}
                    är nu ändrat.
                </p>
                <p className="u-textCenter">Glöm inte att anteckna ditt lösenord.</p>
                <p className="u-textCenter">
                    <a
                        className="Link"
                        href={context.authState.redirectUrl || getCoopseUrl()}
                        rel="noreferrer"
                    >
                        Gå vidare till Coop.se
                    </a>
                </p>
            </>
        );
    }

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Skapa lösenord</h1>

            {!isLoggedInWithDifferentUserAsHinted && fullName && (
                <p className="u-textCenter">
                    BankID inloggning för <strong>{fullName}</strong>
                </p>
            )}
            {changePasswordMutation.isError && (
                <p className="Notice Notice--red">{changePasswordMutation.error.friendlyMessage}</p>
            )}

            {!isUserAuthenticatedWithBankid(context.authState) && (
                <>
                    <p className="Notice Notice--red">Oj, du har visst hamnat fel.</p>
                    <a className="Button" href={context.authState.redirectUrl}>
                        Gå Tillbaka
                    </a>
                </>
            )}
            {isUserAuthenticatedWithBankid(context.authState) && (
                <div className="u-flex u-gap16 u-flexDirectionColumn">
                    <Controller
                        render={({ field, fieldState }) => (
                            <TextInputRHF
                                containerClass="u-marginTmd"
                                label="Nytt lösenord"
                                type="password"
                                value={field.value}
                                name={field.name}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                ref={field.ref}
                                validationText={fieldState.error?.message}
                                isInvalid={!!fieldState.error}
                            />
                        )}
                        name="Password"
                        rules={{
                            required: 'Ange lösenord.',
                            pattern: {
                                value: regex.password,
                                message: 'Lösenordet måste bestå av minst 6 tecken.',
                            },
                        }}
                        defaultValue=""
                        control={control}
                    />
                    <PasswordStrengthIndicator value={watch('Password')} />

                    <Controller
                        render={({ field, fieldState }) => (
                            <TextInputRHF
                                label="Bekräfta nytt lösenord"
                                type="password"
                                value={field.value}
                                name={field.name}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                ref={field.ref}
                                validationText={fieldState.error?.message}
                                isInvalid={!!fieldState.error}
                            />
                        )}
                        name="ConfirmPassword"
                        rules={{
                            required: 'Ange lösenord.',
                            validate: (value) =>
                                value === getValues('Password') ||
                                'Lösenorden matchar inte varandra.',
                        }}
                        defaultValue=""
                        control={control}
                    />
                    <footer className="u-marginTauto">
                        {!isExternalUserAuthenticatedWithBankid(context.authState) ? (
                            <BankId
                                disabled={
                                    changePasswordMutation.isPending ||
                                    changePasswordMutation.isSuccess ||
                                    !formState.isValid
                                }
                                start={start}
                                cancel={cancel}
                                isStarted={isStarted}
                                isCollecting={isCollecting}
                                progress={progress}
                                message={message}
                                errorMessage={errorMessage}
                                autoStartToken={autoStartToken}
                                qrCode={qrCode}
                            />
                        ) : (
                            <Button
                                theme="light"
                                size={48}
                                fullWidth
                                className="u-marginTmd"
                                disabled={
                                    changePasswordMutation.isPending ||
                                    changePasswordMutation.isSuccess ||
                                    !formState.isValid
                                }
                                onClick={changePassword}
                            >
                                Ändra lösenord
                            </Button>
                        )}
                    </footer>
                </div>
            )}
        </>
    );
};

export default ChangePasswordBankid;
