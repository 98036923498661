import { ChipButton } from '@coop/components';
import * as React from 'react';

import type { PunchoutCustomer } from '../../api/punchoutApi';
import { Info, Row, Unit, Wrapper } from './style';

interface Props {
    data?: PunchoutCustomer[];
    onClick: (id: string) => void;
}

const UserList = (props: Props) => {
    const { data } = props;

    return (
        <Wrapper>
            {data?.map((item, index) => (
                <Row key={item.id} fillBackground={index % 2 === 0}>
                    <Unit>
                        {item.organizationName}
                        <Info>
                            <div>
                                <strong>Adress</strong>: {item.address}
                            </div>
                            <div>
                                <strong>GLN</strong>: {item.gln}
                            </div>
                        </Info>
                    </Unit>
                    <ChipButton
                        theme="whiteGreen"
                        type="button"
                        onClick={() => props.onClick(item.id)}
                        size={32}
                    >
                        Logga in
                    </ChipButton>
                </Row>
            ))}
        </Wrapper>
    );
};

export default UserList;
