import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './pages/App';

if (import.meta.hot) {
    import.meta.hot.accept((newModule) => {
        if (newModule) {
            // newModule is undefined when SyntaxError happened
            // eslint-disable-next-line no-console
            console.log('updated: count is now ', newModule.count);
        }
    });
}

// Run app if not IE

const domNode = document.getElementById('root');

const root = createRoot(domNode!);
root.render(<App />);
