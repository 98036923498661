import defaultAxiosInstance from './defaultAxiousClient';

const auth = async (options: {
    method: 'signin' | 'confirm';
    otherDevice: boolean;
    clientId: string;
}) => {
    const url = options.method === 'signin' ? '/bankidlogin/v2/auth' : '/bankidlogin/v2/confirm';
    const result = await defaultAxiosInstance.post<AuthDataDto>(url, {
        otherDevice: options.otherDevice,
        clientId: options.clientId,
    });
    return result;
};

const collect = async (options: { orderRef: string; clientId: string }) => {
    const result = await defaultAxiosInstance.post<CollectResponseDto>(
        '/bankidlogin/v2/local/collect/',
        {
            orderRef: options.orderRef,
            clientId: options.clientId,
        },
    );
    return result;
};

const cancel = async (orderRef: string) => {
    await defaultAxiosInstance.delete<CollectResponseDto>(
        `/bankidlogin/v2/cancel/${encodeURIComponent(orderRef)}`,
    );
};

export const BankIdApi = {
    auth,
    collect,
    cancel,
};

export interface AuthDataDto {
    autostartToken?: string;
    errorCode?: string;
    fullName?: string;
    hintCode?: string;
    message?: string;
    orderRef?: string;
    progressDescription?: string;
    status?: string;
    qrCode?: string;
}

export interface CollectResponseDto {
    hintCode?: string;
    errorCode?: string;
    qrCode?: string;
    status?: string;
    progressDescription?: string;
    surName?: string;
    authGrantCode?: string;
    givenName?: string;
    name?: string;
    personalNumber?: string;
    details?: string;
    message: string;
    accounts?: AccountsDto[];
}

export interface AccountsDto {
    id: string;
    email: string;
    lastLogin: string;
}
