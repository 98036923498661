import React from 'react';

const ConfirmPassword = () => {
    return (
        <div>
            <h1>Bekräfta lösenord</h1>
            <p>Den här funktionen stöds ej längre.</p>
        </div>
    );
};

export default ConfirmPassword;
