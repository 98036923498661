import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';

import type { ApiException } from './api.types';
import { addXsrfTokenToHeader, isApiException } from './api.utils';

const xsrfAxiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});
xsrfAxiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (isApiException(error?.response?.data)) {
            return Promise.reject(error.response.data);
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject<ApiException>({
            friendlyMessage: 'Ett okänt fel inträffade.',
            code: '425',
        });
    },
);
xsrfAxiosInstance.interceptors.request.use(addXsrfTokenToHeader);

const defaultAxiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

defaultAxiosInstance.interceptors.request.use(addXsrfTokenToHeader);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultErrorInterceptor = async (error: any) => {
    const originalRequest: AxiosRequestConfig = error.config;
    if (error?.response?.data?.code === 'TOKEN_VALIDATION_ERROR') {
        try {
            // Refresh the access token
            await refreshXsrfCookie();
            return await xsrfAxiosInstance(originalRequest);
        } catch (refreshError) {
            // Handle token refresh error
            // You can clear all storage and redirect the user to the login page
            return Promise.reject(error.response?.data);
        }
    }

    if (isApiException(error?.response?.data)) {
        return Promise.reject(error.response.data);
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject<ApiException>({
        friendlyMessage: 'Ett okänt fel inträffade',
        code: '424',
    });
};

const refreshXsrfCookie = async () => {
    await defaultAxiosInstance.get('/local/xsrf');
};

defaultAxiosInstance.interceptors.response.use((response) => response, defaultErrorInterceptor);

export default defaultAxiosInstance;
