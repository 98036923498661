import React from 'react';

import LoginEmail from '../LoginEmail';

const LoginCompany = () => {
    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Logga in företag</h1>
            <LoginEmail accountType="Company" />
        </>
    );
};
export default LoginCompany;
