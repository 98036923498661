/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-useless-fragment */
import type { FC } from 'react';
import * as React from 'react';

import type { Page, PageResult } from './Pagination.types';

export interface PageNumberProps {
    page: Page;
    pagingResult: PageResult;
}

const PageNumber: FC<React.PropsWithChildren<PageNumberProps>> = ({ page, pagingResult }) => {
    if (!page) return null;

    if (page.complementaryPageType && page.pageNumber === 1) {
        if (pagingResult.pages.find((x) => x.pageNumber === page.pageNumber + 1)) {
            return <>{page.pageNumber}</>;
        }
        return (
            <>
                {page.pageNumber} <span>...</span>
            </>
        );
    }

    if (page.complementaryPageType && page.pageNumber !== 1) {
        if (pagingResult.pages.find((x) => x.pageNumber === page.pageNumber - 1)) {
            return <>{page.pageNumber}</>;
        }
        return (
            <>
                <span>...</span> {page.pageNumber}
            </>
        );
    }

    if (!page.complementaryPageType) {
        return <>{page.pageNumber}</>;
    }

    return null;
};

export default PageNumber;
