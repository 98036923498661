import './PasswordStrengthIndicator.css';

import React, { useEffect } from 'react';

interface PasswordIndicatorProps {
    value: string;
    className?: string;
}

const PasswordStrengthIndicator = (props: PasswordIndicatorProps) => {
    const [strength, setStrength] = React.useState({
        text: '',
        color: '',
        width: '',
    });

    useEffect(() => {
        setStrength(getStrength(props.value));
    }, [props.value]);

    return props.value ? (
        <div>
            <div className={`PasswordIndicator ${props.className ? props.className : ''}`}>
                <div
                    className="PasswordIndicator-bar"
                    style={{
                        width: props.value ? strength.width : 0,
                        backgroundColor: strength.color,
                    }}
                />
            </div>
            <p className="PasswordIndicator-text">Lösenordsstyrka: {strength.text}</p>
        </div>
    ) : null;
};

const isSecurePassword = (value: string) => {
    const strength = getStrength(value);

    return strength.text === 'Medel' || strength.text === 'Stark';
};

const getStrength = (value: string) => {
    const score = getScore(value);

    if (score > 80) {
        return {
            text: 'Stark',
            color: '#00aa46',
            width: `${score}%`,
        };
    }
    if (score > 60) {
        return {
            text: 'Medel',
            color: 'orange',
            width: `${score}%`,
        };
    }
    return {
        text: 'Låg',
        color: '#ff3300',
        width: `${score}%`,
    };
};

const getScore = (value: string) => {
    let score = 0;

    if (!value) {
        return score;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const letters: any = [];
    for (let i = 0; i < value.length; i += 1) {
        letters[value[i]] = (letters[value[i]] || 0) + 1;
        score += 5.0 / letters[value[i]];
    }

    const variations = {
        digits: /\d/.test(value),
        lower: /[a-z]/.test(value),
        upper: /[A-Z]/.test(value),
        nonWords: /\W/.test(value),
    };

    let variationCount = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const variation of Object.values(variations)) {
        variationCount += variation ? 1 : 0;
    }

    score += (variationCount - 1) * 10;

    return score < 100 ? score : 100;
};

export { isSecurePassword, PasswordStrengthIndicator };
