import React from 'react';
import { useSearchParams } from 'react-router-dom';

const ErrorPage = () => {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    let message = 'Något gick fel. Prova starta om';
    switch (code) {
        case 'NO_AUTOPROVISION_USER':
            message =
                'Du har ingen användare ännu hos oss. För att logga in behövs en användare skapas. Kontakta support för hjälp.';
            break;
        case 'EXTERNAL_CLIENT_MISSING_EMAIL':
            message = 'Din E-postadress kunde inte verifieras. Kontakta support.';
            break;
        case 'NOT_ALLOWED_CLIENT':
            message = 'Din inloggning är inte konfigurerad för att använda den här tjänsten.';
            break;
        case 'FAIL_AUTOPROVISION_USER':
            message = 'Något gick fel när vi försökte skapa upp din användare. Kontakta support.';
            break;
        default:
            break;
    }

    return (
        <div>
            <p>{message}</p>
            <p>
                Felkod: <strong>{code}</strong>
            </p>
        </div>
    );
};

export default ErrorPage;
