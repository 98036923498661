/* eslint-disable react/jsx-props-no-spreading */
import { Button, Checkbox } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { accountApi } from '../../common/api/api.accounts';
import type { ApiException } from '../../common/api/api.types';
import { regex } from '../../common/utils';
import DotVomEmailWarning from '../../components/DotVomEmailWarning/DotVomEmailWarning';
import TextInputRHF from '../../components/TextInputRHF/TextInputRHF';
import useLoginContext from '../../hooks/useLoginContext';
import { SpaRoutes } from '../../hooks/useLoginNavigate';

interface LoginEmailProps {
    accountType: 'Private' | 'Company';
}

const LoginEmail = (props: LoginEmailProps) => {
    const context = useLoginContext();
    const { handleSubmit, formState, watch, control } = useForm<FormDataEmail>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
        defaultValues: { RememberMe: true },
    });
    const returnUrl = context.authState.loginRequest?.returnUrl || context.authState.redirectUrl;

    const signInMutation = useMutation<
        void,
        ApiException,
        {
            email: string;
            password: string;
            accountType: 'Private' | 'Company';
            rememberMe: boolean;
        }
    >({
        mutationFn: (p) =>
            accountApi.signinWithEmailPasswordToApplicationSchema({
                email: p.email,
                password: p.password,
                accountType: p.accountType,
                rememberMe: p.rememberMe,
            }),
        onError: (e) => {
            switch (e.code) {
                case 'USE_EXTERNAL_SIGNIN':
                    window.location.href = SpaRoutes.BuildChallengeExternalUrl(e.field, returnUrl);
                    break;
                default:
                    break;
            }
        },
        onSuccess: () => {
            window.location.href = returnUrl;
        },
    });

    const handleUsernameFormSubmit = (formData: FormDataEmail) => {
        if (formState.isValid) {
            signInMutation.mutate({
                email: formData.Email,
                password: formData.Password,
                accountType: props.accountType,
                rememberMe: formData.RememberMe,
            });
        }
    };

    const isLoading = signInMutation.isPending || signInMutation.isSuccess;

    return (
        <form className="Form" onSubmit={handleSubmit(handleUsernameFormSubmit)}>
            <Controller
                render={({ field, fieldState }) => (
                    <TextInputRHF
                        containerClass="u-marginTmd"
                        label="E-postadress"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                name="Email"
                rules={{
                    required: true,
                    pattern: {
                        value: regex.emailSpaceAtEnd,
                        message: 'Angiven e-postadress har fel format.',
                    },
                }}
                defaultValue=""
                control={control}
            />
            <DotVomEmailWarning value={watch('Email')} />
            <Controller
                render={({ field, fieldState }) => (
                    <TextInputRHF
                        containerClass="u-marginTxsm"
                        label="Lösenord"
                        type="password"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                defaultValue=""
                name="Password"
                rules={{ required: 'Ange lösenord' }}
                control={control}
            />
            <Controller
                key="default"
                render={({ field }) => (
                    <Checkbox
                        id="remember-me"
                        name="RememberMe"
                        label="Kom ihåg mig"
                        className="u-marginTmd"
                        disabled={isLoading}
                        checked={field.value}
                        onBlur={field.onBlur}
                        onCheckedChange={field.onChange}
                    />
                )}
                name="RememberMe"
                control={control}
            />

            <footer className="u-marginTauto">
                {signInMutation.isError && (
                    <p className="Notice Notice--red">{signInMutation.error.friendlyMessage}</p>
                )}

                <Button
                    theme="primary"
                    size={48}
                    fullWidth
                    className="u-marginT"
                    disabled={!formState.isValid || isLoading}
                    type="submit"
                >
                    Logga in
                </Button>

                <p className="u-textCenter u-marginT u-marginBz u-textSmall">
                    <Link
                        className="Link"
                        to={{
                            pathname: SpaRoutes.ForgotPassword.Start,
                            search: window.location.search,
                        }}
                    >
                        Glömt lösenord?
                    </Link>
                </p>
                <p className="u-textCenter u-marginTxsm u-marginBz u-textSmall">
                    {props.accountType === 'Company' ? (
                        <Link
                            className="Link"
                            to={{
                                pathname: SpaRoutes.CreateAccount.Company.Start,
                                search: window.location.search,
                            }}
                        >
                            Skapa ett företagskonto
                        </Link>
                    ) : (
                        <Link
                            className="Link"
                            to={{
                                pathname: SpaRoutes.CreateAccount.Private.Email.Start,
                                search: window.location.search,
                            }}
                        >
                            Skapa ett kundkonto
                        </Link>
                    )}
                </p>
                <EmployeeLogin
                    email={watch('Email')}
                    returnUrl={context.authState.loginRequest?.returnUrl}
                />
            </footer>
        </form>
    );
};

const EmployeeLogin = (props: { email?: string; returnUrl?: string }) => {
    if (!props.email?.length || !props.returnUrl?.length) {
        return null;
    }
    if (!props.email.endsWith('@kf.se') && !props.email.endsWith('@coop.se')) {
        return null;
    }

    return (
        <p className="u-textCenter u-marginTxsm u-marginBz u-textSmall">
            <a
                className="Link "
                href={`/externalauthentication/challange/CoopAzureAd?returnUrl=${encodeURIComponent(
                    props.returnUrl,
                )}`}
            >
                Logga in med Microsoft konto
            </a>
        </p>
    );
};

interface FormDataEmail {
    Email: string;
    Password: string;
    RememberMe: boolean;
    LoginAccounType?: string;
}

export default LoginEmail;
