import './InformationBox.css';

import { Icon } from '@coop/components';
import { Info2Icon } from '@coop/icons';
import React from 'react';

interface InformationBoxProps {
    text: React.ReactNode;
}

const InformationBox = ({ text }: InformationBoxProps) => {
    return (
        <div className="informationbox u-marginTlg u-marginB">
            <div className="informationbox-iconcontainer">
                <Icon icon={Info2Icon} title="Information ikon" />
            </div>
            <div className="informationbox-text">{text}</div>
        </div>
    );
};

export default InformationBox;
