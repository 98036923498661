import { Pagination, Search } from '@coop/components';
import { OverlayLoader } from '@coop/components/src/OverlayLoader/OverlayLoader';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import type { AuthenticationStateResult } from '../../../common/api/api.types';
import useDebounce from '../../../hooks/useDebounce';
import punchoutApi from '../api/punchoutApi';
import Wrapper from './style';
import UserList from './UserList/UserList';

const PUNCHOUT_SEACH_CUSTOMERS_QUERY_KEY = 'getCustomers';
const PAGE_SIZE = 10;
const PunchoutSignin = (props: { authState: AuthenticationStateResult }) => {
    const location = useLocation();
    const [query, setQuery] = useState('');
    const redirectUrl = props.authState.loginRequest?.returnUrl;
    const debouncedQuery = useDebounce(query, 500);
    const [page, setPage] = useState(1);
    const [loginUser, setLoginUser] = useState(false);
    const { error, data, isFetching } = useQuery({
        queryKey: [PUNCHOUT_SEACH_CUSTOMERS_QUERY_KEY, debouncedQuery, page],
        queryFn: () => punchoutApi.getCustomers(debouncedQuery, page - 1, PAGE_SIZE),
        refetchOnMount: false,
        placeholderData: keepPreviousData,
        retry: false,
        staleTime: 10 * (60 * 1000), // 10 min
    });

    const {
        isError: selectError,
        isSuccess: selectSuccess,
        mutate: select,
    } = useMutation({
        mutationFn: (customerId: string) => punchoutApi.loginCustomer(customerId),
    });

    useEffect(() => {
        if (selectSuccess && !loginUser && redirectUrl) {
            setLoginUser(true);
            window.location.href = redirectUrl;
        }
    }, [selectSuccess, loginUser, setLoginUser, redirectUrl]);

    return (
        <Wrapper>
            <>
                <div>
                    <h2>Välkommen till Coops e-handel!</h2>
                    <p>
                        Sök för att hitta den enhet du vill skapa en order för och klicka sedan på
                        “Logga in” för din valda enhet. Observera att du måste välja samma enhet som
                        du ska skapa en order för i ditt inköpssystem. Hittar du inte din enhet,
                        vänligen kontakta vår&nbsp;
                        <a
                            href="https://www.coop.se/coop-kundservice/" // TODO update the link to /foretag/kundservice after B2B is setup
                            target="_blank"
                            rel="noreferrer"
                        >
                            kundservice
                        </a>
                        .
                    </p>
                </div>
                {error && <h2>Fail</h2>}
                <div>
                    <Search
                        setInputText={setQuery}
                        useDropdown={false}
                        inputPlaceholder="Sök enhet"
                        size="small"
                        theme="shadowed"
                    />
                </div>
                <div className="u-posRelative">
                    <OverlayLoader isLoading={isFetching} />
                    <UserList data={data?.customers} onClick={(id: string) => select(id)} />
                </div>
                <Pagination
                    currentPage={page}
                    hideIfOnlyOnePage
                    onPageChange={(e, newPage) => {
                        e.preventDefault();
                        setPage(newPage.pageNumber);
                    }}
                    totalResults={data?.totalCount || 0}
                    resultsPerPage={PAGE_SIZE}
                    paginationRange={7}
                    currentPath=""
                    currentSearchParams={new URLSearchParams(location.search)}
                />
                {selectError && (
                    <h2 style={{ color: '#F00' }}>Something went wrong when trying to sign in</h2>
                )}
            </>
        </Wrapper>
    );
};

export default PunchoutSignin;
