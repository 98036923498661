import { Button } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';

import { accountApi } from '../../../../../common/api/api.accounts';
import type { ApiException } from '../../../../../common/api/api.types';
import useLoginContext from '../../../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../../../hooks/useLoginNavigate';
import useExternalUser from '../../../useExternalUser';

const EmailAlreadyConnected = () => {
    const context = useLoginContext();
    const { givenName } = useExternalUser(context.authState);
    const navigate = useLoginNavigate();
    const { email } = useParams();
    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.createAccountSendEmailVerificationCode,
        onSuccess: () => {
            if (email) {
                navigate(SpaRoutes.CreateAccount.Private.BankId.VerifyExistingEmail(email));
            }
        },
    });

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
        }
    };

    const isLoading = sendVerificationMailMutation.isPending;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Hej {givenName}!</h1>
            <p className="u-textCenter">
                Din e-postadress är redan knuten till ett kundkonto. Du behöver bekräfta att det är
                din <span className="u-textNoWrap">e-postadress</span> genom att ange den
                verifikationskod som vi mailar till:
            </p>
            <p className="u-textCenter">
                <strong>{email}</strong>
            </p>
            {sendVerificationMailMutation.isError && (
                <p className="Notice Notice--red">
                    {sendVerificationMailMutation.error.friendlyMessage}
                </p>
            )}
            <footer className="u-marginTauto">
                <Button
                    theme="primary"
                    size={48}
                    fullWidth
                    disabled={isLoading}
                    className="Button Button--green u-marginT"
                    type="button"
                    onClick={sendEmailVerificationClick}
                >
                    Skicka verifikationskod
                </Button>
                <Button
                    theme="primaryOutline"
                    size={48}
                    fullWidth
                    disabled={isLoading}
                    className="Button Button--invertedGreenDark u-marginTmd"
                    type="button"
                    onClick={() => navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail)}
                >
                    Ange annan e-postadress
                </Button>
            </footer>
        </>
    );
};

export default EmailAlreadyConnected;
