/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../../common/api/api.accounts';
import type { ApiException } from '../../../../common/api/api.types';
import { regex } from '../../../../common/utils';
import DotVomEmailWarning from '../../../../components/DotVomEmailWarning/DotVomEmailWarning';
import { PasswordStrengthIndicator } from '../../../../components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import TextInputRHF from '../../../../components/TextInputRHF/TextInputRHF';
import useLoginContext from '../../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../../hooks/useLoginNavigate';

interface FormFields {
    Email: string;
    Password: string;
    ConfirmPassword: string;
}

const CreateEmailAccount = () => {
    const context = useLoginContext();
    const navigate = useLoginNavigate();
    const { formState, watch, getValues, control } = useForm<FormFields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });

    const submitFormClick = () => {
        if (formState.isValid) {
            sendVerificationMailMutation.mutate({
                email: getValues('Email'),
                password: getValues('Password'),
                confirmPassword: getValues('ConfirmPassword'),
                accountType: 'Company',
            });
        }
    };

    const sendVerificationMailMutation = useMutation<
        void,
        ApiException,
        {
            email: string;
            password: string;
            confirmPassword: string;
            accountType: 'Company';
        }
    >({
        mutationFn: async (params) => {
            await accountApi.signinExternalForNewEmailAccount({
                email: params.email,
                password: params.password,
                accountType: params.accountType,
                confirmPassword: params.confirmPassword,
            });
        },
        onSuccess: () => {
            context.refetchAuthState();
            accountApi.createAccountSendEmailVerificationCode(getValues('Email'));
            navigate(SpaRoutes.CreateAccount.Company.Email.Verify);
        },
    });

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Skapa företagskonto</h1>
            <Controller
                render={({ field, fieldState }) => (
                    <TextInputRHF
                        containerClass="u-marginTmd"
                        label="E-postadress"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        ref={field.ref}
                        validationText={fieldState.error?.message}
                        isInvalid={!!fieldState.error}
                    />
                )}
                name="Email"
                rules={{
                    required: true,
                    pattern: {
                        value: regex.emailSpaceAtEnd,
                        message: 'Angiven e-postadress har fel format.',
                    },
                }}
                defaultValue=""
                control={control}
            />
            <DotVomEmailWarning value={watch('Email')} />
            <div>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInputRHF
                            containerClass="u-marginTmd"
                            label="Lösenord"
                            type="password"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    name="Password"
                    rules={{
                        required: 'Ange lösenord.',
                        pattern: {
                            value: regex.password,
                            message: 'Lösenordet måste bestå av minst 6 tecken.',
                        },
                    }}
                    defaultValue=""
                    control={control}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInputRHF
                            containerClass="u-marginTmd"
                            label="Bekräfta lösenord"
                            type="password"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    name="ConfirmPassword"
                    rules={{
                        required: 'Ange lösenord.',
                        validate: (value) =>
                            value === getValues('Password') || 'Lösenorden matchar inte varandra.',
                    }}
                    defaultValue=""
                    control={control}
                />
            </div>
            <PasswordStrengthIndicator className="u-marginTmd" value={watch('Password')} />

            <footer className="u-marginTauto">
                {sendVerificationMailMutation.isError && (
                    <p className="Notice Notice--red">
                        {sendVerificationMailMutation.error.friendlyMessage}
                    </p>
                )}
                <p className="u-textXSmall u-colorGray u-marginTmd u-textCenter">
                    Genom att skapa ett företagskonto godkänner du{' '}
                    <a
                        className="Link"
                        href="https://www.coop.se/globala-sidor/anvandarvillkor/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Coops villkor
                    </a>
                    .{' '}
                    <a
                        className="Link"
                        href="https://www.coop.se/Globala-sidor/Om-webbplatsen/personuppgifter/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Klicka här
                    </a>{' '}
                    för att läsa om hur Coop hanterar din personliga information.
                </p>
                <Button
                    className="u-marginTmd"
                    size={48}
                    fullWidth
                    type="submit"
                    disabled={!formState.isValid || sendVerificationMailMutation.isPending}
                    onClick={submitFormClick}
                >
                    Skapa företagskonto
                </Button>
                <p className="u-textXSmall u-colorGray u-sm-hidden">
                    This site is protected by reCAPTCHA and the Google{' '}
                    <a
                        className="Link"
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                        className="Link"
                        href="https://policies.google.com/terms"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms of Service
                    </a>{' '}
                    apply.
                </p>
            </footer>
        </>
    );
};

export default CreateEmailAccount;
