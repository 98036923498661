import './DotVomEmailWarning.css';

import React from 'react';

interface DotVomEmailWarningProps {
    value: string;
}

const DotVomEmailWarning = (props: DotVomEmailWarningProps) => {
    return props.value && props.value.toLowerCase().endsWith('.vom') ? (
        <p className="DotVol">Du har angett &quot;.vom&quot;, menade du &quot;.com&quot;?</p>
    ) : null;
};
export default DotVomEmailWarning;
