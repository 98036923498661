import { Button } from '@coop/components';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';

import { getBankIdUrl } from '../../common/utils';
import { useWindowSize } from '../../hooks/useWindowSize';
import type { useBankid } from './BankId.hooks';

const BankId = (
    props: Omit<ReturnType<typeof useBankid>, 'onSuccess' | 'isCompleted'> & {
        disabled?: boolean;
    },
) => {
    const windowSize = useWindowSize();
    const desktopScreen = windowSize.width > 1024;
    if (props.isStarted) {
        return (
            <>
                {!!props.autoStartToken && !props.qrCode && (
                    <div className="u-textCenter">
                        <h3 className="u-marginTz">Startar inte BankID?</h3>
                        <a className="Link" href={getBankIdUrl(props.autoStartToken)}>
                            Starta manuellt
                        </a>
                    </div>
                )}
                {!!props.qrCode && (
                    <div className="u-textCenter">
                        <QRCodeSVG width={256} height={256} value={props.qrCode} />
                    </div>
                )}

                {props.message && <p className="u-textCenter u-marginTz">{props.message}</p>}
                {props.isCollecting && (
                    <Button
                        theme="primaryOutline"
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        key="cancelButton"
                        onClick={props.cancel}
                    >
                        Avbryt
                    </Button>
                )}
            </>
        );
    }

    return (
        <div className="u-flex u-gap16 u-flexDirectionColumn">
            {props.errorMessage && <p className="u-textCenter u-marginTz">{props.errorMessage}</p>}
            {desktopScreen ? (
                <>
                    <Button
                        theme="light"
                        className=""
                        fullWidth
                        size={48}
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('thisDevice')}
                    >
                        BankID på den här datorn
                    </Button>
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('otherDevice')}
                    >
                        BankID på en annan enhet
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('thisDevice')}
                    >
                        Starta Mobilt BankID
                    </Button>
                    <Button
                        theme="light"
                        size={48}
                        fullWidth
                        disabled={props.isStarted || props.disabled}
                        onClick={() => props.start('otherDevice')}
                    >
                        BankID på en annan enhet
                    </Button>
                </>
            )}
        </div>
    );
};

export default BankId;
