import React from 'react';

import AppContext from '../context/appContext';

const useLoginContext = () => {
    const context = React.useContext(AppContext);
    if (context === null) throw new Error('Invalid context');
    return context;
};

export default useLoginContext;
