import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type CollectResponseDto, BankIdApi } from '../../common/api/api.bankid';
import type { ApiException } from '../../common/api/api.types';
import { isApiException } from '../../common/api/api.utils';
import { getBankIdUrl } from '../../common/utils';
import { isExternalUserAuthenticatedWithBankid } from '../../core/authState.utils';
import queryKeys from '../../core/queryKeys';
import useLoginContext from '../../hooks/useLoginContext';

const COLLECT_QUERY_PARAMETER = 'collectingBankidRef';
type AuthMode = 'otherDevice' | 'thisDevice';
type Progress = 'pending' | 'complete' | 'failed';
/**
 * Ios is doing an refresh after return from bankid app. So this must work even if browser is refreshed in collecting state.
 */
export const useBankid = (config: {
    isConfirm: boolean;
    onSuccessAsync?: (data: CollectResponseDto) => Promise<void>;
    onSuccess?: (data: CollectResponseDto) => void;
}) => {
    const context = useLoginContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const isCollecting = searchParams.has(COLLECT_QUERY_PARAMETER);
    const collectingOrderRef = searchParams.get(COLLECT_QUERY_PARAMETER);
    const [progress, setProgress] = useState<Progress | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [message, setMessage] = useState<string | undefined>();
    const reset = () => {
        if (searchParams.has(COLLECT_QUERY_PARAMETER)) {
            searchParams.delete(COLLECT_QUERY_PARAMETER);
            setSearchParams(searchParams, { replace: true });
        }
    };

    const start = async (authMode: AuthMode) => {
        authMutate.mutate(authMode === 'otherDevice');
    };

    const cancel = () => {
        if (collectingOrderRef) {
            BankIdApi.cancel(collectingOrderRef);
        }
        reset();
    };
    // the client who initiated this call
    const clientId = context.authState.loginRequest?.clientId || 'local';

    const authMutate = useMutation({
        mutationFn: async (otherDevice: boolean) => {
            const result = await BankIdApi.auth({
                method: config.isConfirm ? 'confirm' : 'signin',
                otherDevice,
                clientId,
            });
            if (isApiException(result.data)) {
                setProgress('failed');
                setErrorMessage(result.data.friendlyMessage);
            }

            return result.data;
        },
        onError: (err) => {
            setProgress('failed');
            if (isApiException(err)) {
                setMessage(err.friendlyMessage);
            } else {
                setMessage('Något gick fel');
            }
        },
        onSuccess: (data) => {
            if (data?.orderRef) {
                searchParams.set(COLLECT_QUERY_PARAMETER, data.orderRef);
                setSearchParams(searchParams.toString());
                setTimeout(() => {
                    if (data.autostartToken && !data.qrCode) {
                        window.location.href = getBankIdUrl(data.autostartToken);
                    }
                });
            }
        },
    });

    const collectQuery = useQuery<CollectResponseDto, ApiException>({
        queryKey: [queryKeys.bankid.collect, collectingOrderRef],
        queryFn: async () => {
            let response: AxiosResponse<CollectResponseDto, unknown>;
            try {
                response = await BankIdApi.collect({
                    clientId,
                    orderRef: collectingOrderRef!,
                });
            } catch (err) {
                reset();
                setProgress('failed');
                if (isApiException(err)) {
                    setErrorMessage(err.friendlyMessage);
                }
                throw err;
            }

            const { data } = response;

            if (isApiException(data)) {
                setProgress('failed');
                setErrorMessage(data.friendlyMessage);
                reset();
                return data;
            }

            if (data.hintCode === 'userCancel' || data.errorCode || data.status === 'failed') {
                setErrorMessage(data.message);
                setProgress('failed');
                reset();
                return data;
            }

            setProgress('pending');
            setMessage(data.message);

            if (data.status === 'complete') {
                await context.refetchAuthState();

                reset();
                setProgress('complete');

                await config.onSuccessAsync?.(data);
                config.onSuccess?.(data);
            }
            return data;
        },
        refetchInterval: 2000, // Poll every 2000ms
        enabled: isCollecting,
    });

    const isCompleted =
        isExternalUserAuthenticatedWithBankid(context.authState) && progress === 'complete';

    const isStarted = authMutate.isPending || isCollecting || collectQuery.isFetching;
    return {
        start,
        cancel,
        /** Signed in with bankid as external user */
        isCompleted,
        /** When progress is complete, we are all done and authenticated */
        progress,
        /** if the bankid process is running */
        isStarted,
        isCollecting,
        autoStartToken: authMutate.data?.autostartToken,
        message,
        /** QR code as string */
        qrCode: collectQuery?.data?.qrCode || authMutate.data?.qrCode,
        errorMessage,
    };
};
