/* eslint-disable react/jsx-props-no-spreading */
import '../../../../../components/Form/Form.css';

import { Button, PacmanLoader } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';

import { accountApi } from '../../../../../common/api/api.accounts';
import type { ApiException } from '../../../../../common/api/api.types';
import { regex } from '../../../../../common/utils';
import TextInputRHF from '../../../../../components/TextInputRHF/TextInputRHF';
import { getExternalUserEmail } from '../../../../../core/authState.utils';
import useLoginContext from '../../../../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../../../../hooks/useLoginNavigate';

interface VerifyFormData {
    verificationCode: string;
}
const VerifyEmail = () => {
    const context = useLoginContext();
    const [disabledSendAgainButton, setDisabledSendAgainButton] = useState(true);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        let handle: NodeJS.Timeout;
        if (disabledSendAgainButton) {
            handle = setTimeout(() => setDisabledSendAgainButton(false), 10000);
        }
        return () => clearTimeout(handle);
    }, [disabledSendAgainButton]);

    const { handleSubmit, formState, control } = useForm<VerifyFormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const navigate = useLoginNavigate();

    const createNewAccountMutation = useMutation<void, ApiException, { verificationCode: string }>({
        mutationFn: async (params) => {
            if (executeRecaptcha) {
                const recaptcha = await executeRecaptcha();
                await accountApi.createNewAccountWithEmail({
                    verificationCode: params.verificationCode,
                    recaptcha,
                });
            }
        },
        onSuccess: () => {
            navigate(SpaRoutes.Login.RememberMe);
        },
        onError: (error) => {
            switch (error.code) {
                case 'NO_EXTERNAL_SIGNIN':
                    navigate(SpaRoutes.CreateAccount.Private.Start);
                    break;
                default:
                    break;
            }
        },
    });
    const email = getExternalUserEmail(context.authState);
    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.createAccountSendEmailVerificationCode,
    });

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
            setDisabledSendAgainButton(true);
        }
    };

    const handleStartFormSubmit = (formData: VerifyFormData) => {
        if (formState.isValid) {
            createNewAccountMutation.mutate({ verificationCode: formData.verificationCode });
        }
    };
    const isLoading = createNewAccountMutation.isPending || sendVerificationMailMutation.isPending;

    return (
        <>
            <h1 className="Heading Heading--large u-textCenter">Ange verifikationskod</h1>
            <p className="u-textCenter">
                Ange verifikationskoden du fick i mailet skickat till <strong>{email}</strong>
            </p>
            <form className="Form" onSubmit={handleSubmit(handleStartFormSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInputRHF
                            containerClass="u-marginTmd"
                            label="Verifikationskod"
                            type="number"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    defaultValue=""
                    name="verificationCode"
                    rules={{
                        required: 'Ange verifikationskod',
                        pattern: {
                            value: regex.verificationCode,
                            message: 'Ange verifikationskod',
                        },
                    }}
                    control={control}
                />
                <footer className="u-marginTauto">
                    <div className="u-flex u-flexCenter">
                        <PacmanLoader className="u-marginT" isLoading={isLoading} />
                    </div>
                    {createNewAccountMutation.isError && (
                        <p className="Notice Notice--red">
                            {createNewAccountMutation.error.friendlyMessage}
                        </p>
                    )}
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading || !executeRecaptcha}
                    >
                        Verifiera
                    </Button>
                    <Button
                        theme="primaryOutline"
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        disabled={disabledSendAgainButton || isLoading || !executeRecaptcha}
                        onClick={sendEmailVerificationClick}
                    >
                        Skicka verifikationskoden igen
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default VerifyEmail;
