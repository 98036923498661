import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import RadioSwitch from '../../components/RadioSwitch/RadioSwitch';
import useLoginContext from '../../hooks/useLoginContext';
import useLoginNavigate, { SpaRoutes } from '../../hooks/useLoginNavigate';
import CreateNewAccountCompany from './foretag/CreateNewAccountCompany';
import CreateNewAccountPrivate from './privat/CreateNewAccountPrivate';

const headerRoutes = [
    SpaRoutes.CreateAccount.Company.Start,
    SpaRoutes.CreateAccount.Private.BankId.Start,
    SpaRoutes.CreateAccount.Private.Email.Start,
];

const CreateNewAccount = () => {
    const location = useLocation();
    const context = useLoginContext();

    React.useEffect(() => {
        context?.setShowNavigation(true);
    }, [context, location.pathname]);

    const navigate = useLoginNavigate();
    const setAccountTypeFromRadio = (e?: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.value) {
            if (e.target.value === 'Private') {
                navigate(SpaRoutes.CreateAccount.Private.BankId.Start);
            } else {
                navigate(SpaRoutes.CreateAccount.Company.Start);
            }
        }
    };
    const isCompanyRoute = location.pathname.startsWith(SpaRoutes.CreateAccount.Company.Start);
    // lets trim ending slash
    const path = location.pathname.replace(/\/$/, '');
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Le8ZqEaAAAAAC-7NuPfu7cfOWWQA8_iwp6qzg1d">
            {headerRoutes.includes(path) && (
                <RadioSwitch
                    name="PrivateOrCompany"
                    className="u-marginBmd"
                    labels={['Privat', 'Företag']}
                    values={['Private', 'Company']}
                    id="accountType"
                    defaultValue={isCompanyRoute ? 'Company' : 'Private'}
                    onChange={setAccountTypeFromRadio}
                />
            )}
            <Routes>
                <Route path={'/privat/*'} element={<CreateNewAccountPrivate />} />
                <Route path={'/foretag/*'} element={<CreateNewAccountCompany />} />
                <Route
                    path={'/*'}
                    element={
                        <Navigate
                            to={{
                                pathname: SpaRoutes.CreateAccount.Private.BankId.Start,
                                search: location.search,
                            }}
                        />
                    }
                />
            </Routes>
        </GoogleReCaptchaProvider>
    );
};

export default CreateNewAccount;
