import type { AuthenticationStateResult } from '../common/api/api.types';

export const isUserAuthenticatedWithBankid = (authState: AuthenticationStateResult) =>
    !!authState.currentUser?.authenticationMethods?.some((method) => method === 'bankid');

export const isExternalUserAuthenticatedWithBankid = (authState: AuthenticationStateResult) =>
    !!authState.currentExternalUser?.claims?.some((c) => c.type === 'amr' && c.value === 'bankid');

export const isExternalUserAuthenticatedWithPassword = (authState: AuthenticationStateResult) =>
    !!authState.currentExternalUser?.claims?.some((c) => c.type === 'amr' && c.value === 'pwd');

export const isUserAuthenticatedWithPassword = (authState: AuthenticationStateResult) =>
    !!authState.currentUser?.authenticationMethods?.some((method) => method === 'pwd');

export const isLoginStateBankidOnly = (authState: AuthenticationStateResult) =>
    !!authState?.loginRequest?.loginType?.split(' ').includes('bankidonly');

export const isLoginStateNoRemember = (authState: AuthenticationStateResult) =>
    !!authState?.loginRequest?.loginType?.split(' ').includes('noremember');

export const isExternalUserCompany = (authState: AuthenticationStateResult) =>
    !!authState.currentExternalUser?.claims?.some(
        (c) => c.type === 'isCompany' && c.value === 'True',
    );

export const getExternalUserEmail = (authState: AuthenticationStateResult) => {
    const emailClaim = authState.currentExternalUser?.claims?.find((c) => c.type === 'email');
    return emailClaim?.value;
};
