import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { isUserAuthenticatedWithBankid } from '../../core/authState.utils';
import useLoginContext from '../../hooks/useLoginContext';
import { SpaRoutes } from '../../hooks/useLoginNavigate';
import ChangePasswordBankid from './bankid/ChangePasswordBankid';
import ChangePasswordEmail from './email/ChangePasswordEmail';

const ChangePassword = () => {
    const context = useLoginContext();
    const location = useLocation();
    return (
        <Routes>
            <Route path="bankid" element={<ChangePasswordBankid />} />
            <Route path="e-post" element={<ChangePasswordEmail />} />
            <Route
                path="*"
                element={
                    <Navigate
                        to={{
                            pathname: isUserAuthenticatedWithBankid(context.authState)
                                ? SpaRoutes.ChangePassword.Bankid
                                : SpaRoutes.ChangePassword.Email,
                            search: location.search,
                        }}
                    />
                }
            />
        </Routes>
    );
};

export default ChangePassword;
