/* eslint-disable react/jsx-props-no-spreading */
import '../../../../../../components/Form/Form.css';

import { Button, PacmanLoader } from '@coop/components';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { accountApi } from '../../../../../../common/api/api.accounts';
import type { ApiException } from '../../../../../../common/api/api.types';
import { regex } from '../../../../../../common/utils';
import TextInputRHF from '../../../../../../components/TextInputRHF/TextInputRHF';
import useLoginNavigate, { SpaRoutes } from '../../../../../../hooks/useLoginNavigate';

interface FormData {
    verificationCode: string;
}
const VerifyEmailExistingAccount = () => {
    const [disabledSendAgainButton, setDisabledSendAgainButton] = useState(true);
    const { email } = useParams();

    useEffect(() => {
        let handle: NodeJS.Timeout;
        if (disabledSendAgainButton) {
            handle = setTimeout(() => setDisabledSendAgainButton(false), 10000);
        }
        return () => clearTimeout(handle);
    }, [disabledSendAgainButton]);

    const { handleSubmit, formState, control } = useForm<FormData>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        shouldUnregister: true,
    });
    const navigate = useLoginNavigate();
    if (!email) {
        navigate(SpaRoutes.CreateAccount.Private.BankId.RegisterEmail);
    }
    const verifyEmailMutation = useMutation<
        void,
        ApiException,
        Parameters<typeof accountApi.connectEmailWithBankid>
    >({
        mutationFn: (params) => accountApi.connectEmailWithBankid(params[0], params[1]),
        onSuccess: () => {
            navigate(SpaRoutes.Login.RememberMe);
        },
    });

    const sendVerificationMailMutation = useMutation<void, ApiException, string>({
        mutationFn: accountApi.createAccountSendEmailVerificationCode,
    });

    const sendEmailVerificationClick = () => {
        if (email) {
            sendVerificationMailMutation.mutateAsync(email);
            verifyEmailMutation.reset();
        }
    };

    const handleStartFormSubmit = (formData: FormData) => {
        if (formState.isValid && email) {
            verifyEmailMutation.mutate([email, formData.verificationCode]);
        }
    };
    const isLoading = verifyEmailMutation.isPending || sendVerificationMailMutation.isPending;

    if (verifyEmailMutation.isError) {
        if (verifyEmailMutation.error.code === 'NO_EXTERNAL_SIGNIN') {
            return (
                <>
                    <h1 className="Heading Heading--large u-textCenter">Ange verifikationskod</h1>
                    <p className="u-textCenter">
                        Det tog för lång tid att verifiera kontot. Starta om flödet och försök på
                        nytt.
                    </p>
                    <Button
                        theme="primaryOutline"
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        type="button"
                        onClick={() => navigate(SpaRoutes.CreateAccount.Private.BankId.Start)}
                    >
                        Skapa nytt konto
                    </Button>
                </>
            );
        }
    }

    return (
        <>
            <>
                <h1 className="Heading Heading--large u-textCenter">Ange verifikationskod</h1>
                <p className="u-textCenter">
                    Ange verifikationskoden du fick i mailet skickat till <strong>{email}</strong>
                </p>
            </>
            <form className="Form" onSubmit={handleSubmit(handleStartFormSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <TextInputRHF
                            containerClass="u-marginTmd"
                            label="Verifikationskod"
                            type="number"
                            value={field.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            ref={field.ref}
                            validationText={fieldState.error?.message}
                            isInvalid={!!fieldState.error}
                        />
                    )}
                    defaultValue=""
                    name="verificationCode"
                    rules={{
                        required: 'Ange verifikationskod',
                        pattern: {
                            value: regex.verificationCode,
                            message: 'Ange verifikationskod',
                        },
                    }}
                    control={control}
                />
                <footer className="u-marginTauto">
                    <div className="u-flex u-flexCenter">
                        <PacmanLoader className="u-marginT" isLoading={isLoading} />
                    </div>
                    {verifyEmailMutation.isError && (
                        <p className="Notice Notice--red">
                            {verifyEmailMutation.error.friendlyMessage}
                        </p>
                    )}
                    <Button
                        theme="primary"
                        size={48}
                        fullWidth
                        className="u-marginT"
                        type="submit"
                        disabled={!formState.isValid || isLoading}
                    >
                        Verifiera
                    </Button>
                    <Button
                        theme="primaryOutline"
                        size={48}
                        fullWidth
                        className="u-marginTmd"
                        type="button"
                        disabled={disabledSendAgainButton || isLoading}
                        onClick={sendEmailVerificationClick}
                    >
                        Skicka verifikationskoden igen
                    </Button>
                </footer>
            </form>
        </>
    );
};

export default VerifyEmailExistingAccount;
