interface PunchoutCustomerResult {
    totalCount: number;
    customers: PunchoutCustomer[];
}
export interface PunchoutCustomer {
    id: string;
    name: string;
    organizationName: string;
    gln: string;
    organizationId: string;
    address: string;
}

const getCustomers = (
    query: string,
    page: number,
    pageSize: number,
): Promise<PunchoutCustomerResult> => {
    return fetch('/punchout/getcustomers', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query, page, pageSize }),
        method: 'POST',
    }).then((res) => res.json());
};

const loginCustomer = (customerId: string) =>
    fetch(`/punchout/logincustomer`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerId }),
        method: 'POST',
    });

const punchoutApi = {
    getCustomers,
    loginCustomer,
};

export default punchoutApi;
