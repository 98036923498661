/* eslint-disable no-undef */
export default function dispatchEvent(name, payload) {
    const message = {
        event: name,
        payload,
    };

    if (window.webkit?.messageHandlers?.observer?.postMessage) {
        window.webkit.messageHandlers.observer.postMessage(message);
        return true;
    }
    if (window.Android?.postMessage) {
        window.Android.postMessage(JSON.stringify(message));
        return true;
    }
    if (window.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
        return true;
    }
    if (window?.postMessage) {
        window?.postMessage(JSON.stringify(message));
    }
    return false;
}
