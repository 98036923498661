import type { AuthenticationStateResult } from './api.types';
import defaultAxiosInstance from './defaultAxiousClient';

const getUserState = async (returnUrl?: string, logoutId?: string) => {
    const response = await defaultAxiosInstance.get<AuthenticationStateResult>('/local/state', {
        params: {
            returnUrl,
            logoutId,
        },
    });
    return response.data;
};

export const userStateApi = {
    getUserState,
};
